import React from "react"

import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  CTA,
  VideoPlayer
} from "@/components"

export default function Principles() {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light" squeeze={true}>
      <SEO
        title="Accelerated Learning - Principles"
        description="A Free Training from Sonora Guitar Intensive."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection="column"
          pt={["7.2rem", null, null, "2.2rem"]}
          pb={["0", null, null, "6.9rem"]}
        >
        <Box
            width={["100%", 12 / 12]}
            textAlign="left"
            mb={["2rem", null, null, "0rem"]}
            pr={["0", "0rem"]}
        >
            <VideoPlayer
                width="auto"
                maxWidth="auto"
                url="https://vimeo.com/496296396/f742369591"
            />

        </Box>
        <Box mb={["2rem", null, null, "0"]}>
            <CTA variant="special" to="/application" mb="1.2rem" mt="3rem">
                Apply Now
            </CTA>
        </Box>
        </Width>
      </Box>
    </Layout>
  )
}
